import { Scene } from '@babylonjs/core/scene'
import { ShaderMaterial } from '@babylonjs/core/Materials/shaderMaterial'
import { Effect } from '@babylonjs/core/Materials/effect'
import { Vector3, Vector4 } from '@babylonjs/core/Maths/math.vector'

import * as defaultVertexShader from '@/materials/shaders/default.vertex.glsl'
import * as ghostChamberFragmentShader from '@/materials/shaders/GhostChamber/ghostChamber.fragment.glsl'

Effect.ShadersStore['ghostChamberVertexShader'] = defaultVertexShader
Effect.ShadersStore['ghostChamberFragmentShader'] = ghostChamberFragmentShader

export class GhostChamberMaterial extends ShaderMaterial {

    constructor(name: string, scene: Scene) {
        super(name, scene, { vertex: 'ghostChamber', fragment: 'ghostChamber' }, {
            uniforms: [
                'worldViewProjection',
                'iResolution',
                'time',
                'iTimeDelta',
                'iFrame',
                'iChannelTime[4]',
                'iChannelResolution[4]',
                'iMouse',
                'iChannel0..3',
                'iDate',
                'iSampleRate'
            ],
            attributes: [
                'position',
                'normal',
                'uv'
            ],
        })

        this.resolution = new Vector3(1920, 1080, 0)

        this.timeDelta = 1

        const startTime = Date.now()

        scene.registerBeforeRender(() => {
            const currentTime = Date.now()
            const time = currentTime - startTime

            this.time = time  / 2500
        })
    }

    set time(value: number) {
        this.setFloat('time', value)
    }

    set timeDelta(value: number) {
      this.setFloat('iTimeDelta', value)
  }

    set resolution(value: Vector3) {
      this.setVector3('iResolution', value)
    }

    set channelResolution(value: Vector4) {
      this.setVector4('iChannelResolution[4]', value)
    }
}
