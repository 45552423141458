import Vue from 'vue'
import { Mixin } from 'vue-mixin-decorator'
import utils from '@/utils'

@Mixin
export default class UtilsMixin extends Vue {
  public routes = utils.routes

  public configData = utils.configData

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public deepCopy(obj: any): any {
    return utils.deepCopy(obj)
  }

  public getValue(obj: string | Array<string | null>): string {
    // if object is an array, return the first element, otherwise return the string, or just return empty string if not present
    return Array.isArray(obj) ? obj[0] as string : obj
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public formatDateTime(date: any): string {
    // https://stackoverflow.com/questions/3552461/how-to-format-a-javascript-date
    // const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }
    // return (new Date(date)).toLocaleDateString('en-US', options)

    return (new Date(date)).toLocaleString('en-US', { timeZone: 'UTC' })
  }

  public formatCurrency(currency: number): string {
    const currencyFormatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })
    return currencyFormatter.format(currency)

    // putting this here in case find out that Intl.NumberFormat isn't compatible with older browsers still being used by customers
    // return currency.toLocaleString('en-US', { style: 'currency', currency: 'USD' }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public formatDate(date: any): string {
    // https://stackoverflow.com/questions/3552461/how-to-format-a-javascript-date
    // const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }
    // return (new Date(date)).toLocaleDateString('en-US', options)

    return (new Date(date)).toLocaleDateString('en-US', { timeZone: 'UTC' })
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public formatDateField(date: any): string | null {
    if (!date) {
      return null
    }

    const [year, month, dayAndTime] = date.split('-')
    const [day, time] = dayAndTime.split('T')
    // console.log('date',date,date.split('-'), `${month}/${day}/${year}`, time)
    return `${month}/${day}/${year}`
  }

  public get ccDebug(): string | undefined {
    return window.localStorage.debug
  }

  public get isSmall(): boolean {
    return this.$vuetify.breakpoint.smAndDown
  }

  public get isXsOnly(): boolean | null {
    if (this.$vuetify.breakpoint.name === 'xs') {
      return true
    } else {
      return null
    }
  }
}
