




import { Component, Vue } from 'vue-property-decorator'

import { BlueSmokeMaterial } from '@/materials/BlueSmokeMaterial'

import { GhostChamberMaterial } from '@/materials/GhostChamberMaterial'

import { Engine } from '@babylonjs/core/Engines'
import { Scene } from '@babylonjs/core/scene'
import { Color3, Color4 } from '@babylonjs/core/Maths/math.color'
import { Vector3 } from '@babylonjs/core/Maths/math.vector'
import { UniversalCamera } from '@babylonjs/core/Cameras/universalCamera'
import { HemisphericLight } from '@babylonjs/core/Lights/hemisphericLight'
import { DirectionalLight } from '@babylonjs/core/Lights/directionalLight'
import { MeshBuilder } from '@babylonjs/core/Meshes/meshBuilder'
import { StandardMaterial } from '@babylonjs/core/Materials/standardMaterial'
import { Texture } from '@babylonjs/core/Materials/Textures/texture'
import { Mesh } from '@babylonjs/core/Meshes/mesh'
import { ShaderMaterial } from '@babylonjs/core/Materials/shaderMaterial'
import { ExplosionMaterial } from '@/materials/ExplosionMaterial'
import { PoolMaterial } from '@/materials/PoolMaterial'
// import { Color3, Color4, Vector3 } from '@babylonjs/core/Maths'
// import { UniversalCamera } from '@babylonjs/core/Cameras'
// import { DirectionalLight, HemisphericLight } from '@babylonjs/core/Lights'
// import { MeshBuilder } from '@babylonjs/core/Meshes'
// import { StandardMaterial, Texture } from '@babylonjs/core/Materials'

@Component({
  name: 'cardano-logo'
})
export default class CardanoLogo extends Vue {

  private materials: ShaderMaterial[] = []

  private cardanoMaterial!: StandardMaterial

  private box!: Mesh

  private materialIndex = 0

  private mounted() {

    const view = this.$refs.view as HTMLCanvasElement
    const engine = new Engine(view, true)

    const scene = new Scene(engine)

    scene.clearColor = new Color4(1, 1, 1, 1)

    // const camera = new ArcRotateCamera(
    // "camera",
    // Math.PI / 2,
    // Math.PI / 3.2,
    // 2,
    // Vector3.Zero(),
    // scene)

    const camera = new UniversalCamera('camera', new Vector3(0, 0, -25), scene)

    camera.attachControl(view)

    new HemisphericLight(
      'light',
      new Vector3(0, 1, 0.5),
      scene)


    const directionalLight = new DirectionalLight(
      'light1',
      new Vector3(0, 1, 0),
      scene)

      directionalLight.intensity = 1

      directionalLight.diffuse = new Color3(1, 1, 1)

      directionalLight.position = new Vector3(3, 9, 3);

    // const mesh = MeshBuilder.CreateGround("mesh", {}, scene)

    // const material =  new BlueSmokeMaterial("material", scene)
    // mesh.material = material

    //const ghostMaterial =

    this.materials.push(new PoolMaterial("poolmaterial", scene))

    this.materials.push(new GhostChamberMaterial("ghostmaterial", scene))

    // const material = new BlueSmokeMaterial("material", scene)
    this.materials.push(new BlueSmokeMaterial("bluesmokematerial", scene))

    this.materials.push(new ExplosionMaterial("explosion", scene))


    // const box = MeshBuilder.CreateBox('box', { size: 210 }, scene)
    this.box = MeshBuilder.CreateBox('box', { size: 3000, sideOrientation: 1 }, scene)
    this.box.position = new Vector3(0,0,205)
    this.box.material = this.materials[this.materialIndex]

    // const cardanoMaterial = new BabylonTSMaterial("material", scene)

    this.cardanoMaterial = new StandardMaterial('material', scene)
    this.cardanoMaterial.bumpTexture = new Texture('textures/moon.jpg', scene)
    this.cardanoMaterial.diffuseColor = new Color3(1, 1, 1)
    // this.cardanoMaterial.emissiveTexture = new Texture('textures/moon.png', scene)
    this.cardanoMaterial.emissiveColor = new Color3(.25, .25, .25)
    // this.cardanoMaterial.alpha = 0.5

    // const Writer = MeshWriter(scene, { scale: 0.1, defaultFont:'Arial'})

    // meshwriter tries to automatically tie to BABYLON, but it doesn't exist...

    // const text  = new Writer(
    //                     "IJKLMNÑOPQRSTUVWXYZ",
    //                     {
    //                         "anchor": "center",
    //                         "letter-height": 40,
    //                         "color": "#1C3870",
    //                         "position": {
    //                             "z": 20
    //                         }
    //                     }
    //                 );

    // const textMesh = text.getMesh()

    const sphere1_1 = MeshBuilder.CreateSphere('layer1sphere', {  diameter: .9 }, scene)
    sphere1_1.position = new Vector3(.5, 1, 0)
    sphere1_1.material = this.cardanoMaterial
    // sphere1_1.material.wireframe = true
    const sphere1_5 = sphere1_1.clone()
    sphere1_5.position = new Vector3(.5, -1, 0)

    const sphere1_3 = sphere1_1.clone()
    sphere1_3.position = new Vector3(1, 0, 0)
    const sphere1_4 = sphere1_1.clone()
    sphere1_4.position = new Vector3(-1, 0, 0)

    const sphere1_2 = sphere1_1.clone()
    sphere1_2.position = new Vector3(-.5, 1, 0)
    const sphere1_6 = sphere1_1.clone()
    sphere1_6.position = new Vector3(-0.5, -1, 0)

    const sphere2_1 = MeshBuilder.CreateSphere('layer2sphere', {  diameter: .65 }, scene)
    sphere2_1.position = new Vector3(0, 1.9, 0)
    sphere2_1.material = this.cardanoMaterial

    const sphere2_2 = sphere2_1.clone()
    sphere2_2.position = new Vector3(0, -1.9, 0)

    const sphere2_3 = sphere2_1.clone()
    sphere2_3.position = new Vector3(-1.5, .9, 0)

    const sphere2_4 = sphere2_1.clone()
    sphere2_4.position = new Vector3(-1.5, -0.9, 0)

    const sphere2_5 = sphere2_1.clone()
    sphere2_5.position = new Vector3(1.5, .9, 0)

    const sphere2_6 = sphere2_1.clone()
    sphere2_6.position = new Vector3(1.5, -0.9, 0)

    const sphere3_1 = MeshBuilder.CreateSphere('layer3sphere', {  diameter: .55 }, scene)
    sphere3_1.position = new Vector3(-2.3, 0, 0)
    sphere3_1.material = this.cardanoMaterial

    const sphere3_2 = sphere3_1.clone()
    sphere3_2.position = new Vector3(2.3, 0, 0)

    const sphere3_3 = sphere3_1.clone()
    sphere3_3.position = new Vector3(-1.25, -2, 0)

    const sphere3_4 = sphere3_1.clone()
    sphere3_4.position = new Vector3(-1.25, 2, 0)

    const sphere3_5 = sphere3_1.clone()
    sphere3_5.position = new Vector3(1.25, -2, 0)

    const sphere3_6 = sphere3_1.clone()
    sphere3_6.position = new Vector3(1.25, 2, 0)

    const sphere4_1 = MeshBuilder.CreateSphere('layer4sphere', {  diameter: .45 }, scene)
    sphere4_1.position = new Vector3(2.5, 1.75, 0)
    sphere4_1.material = this.cardanoMaterial

    const sphere4_2 = sphere4_1.clone()
    sphere4_2.position = new Vector3(2.5, -1.75, 0)

    const sphere4_3 = sphere4_1.clone()
    sphere4_3.position = new Vector3(0, 3, 0)

    const sphere4_4 = sphere4_1.clone()
    sphere4_4.position = new Vector3(0, -3, 0)

    const sphere4_5 = sphere4_1.clone()
    sphere4_5.position = new Vector3(-2.5, 1.75, 0)

    const sphere4_6 = sphere4_1.clone()
    sphere4_6.position = new Vector3(-2.5, -1.75, 0)

    const sphere5_1 = MeshBuilder.CreateSphere('layer5sphere', {  diameter: .35 }, scene)
    sphere5_1.position = new Vector3(-3.25, 0, 0)
    sphere5_1.material = this.cardanoMaterial

    const sphere5_2 = sphere5_1.clone()
    sphere5_2.position = new Vector3(3.25, 0, 0)

    const sphere5_3 = sphere5_1.clone()
    sphere5_3.position = new Vector3(-1.75, -2.9, 0)

    const sphere5_4 = sphere5_1.clone()
    sphere5_4.position = new Vector3(-1.75, 2.9, 0)

    const sphere5_5 = sphere5_1.clone()
    sphere5_5.position = new Vector3(1.75, -2.9, 0)

    const sphere5_6 = sphere5_1.clone()
    sphere5_6.position = new Vector3(1.75, 2.9, 0)

    engine.runRenderLoop(() => {
        scene.render();

        // box.rotate(new Vector3(1, 1, 0), .005)

        // sphere1_3.translate(new Vector3(1, 0, 0), .05)
        // sphere3_2.translate(new Vector3(1, 0, 0), .04)
        // sphere5_2.translate(new Vector3(1, 0, 0), .03)

        // sphere4_1.translate(new Vector3(1, 0, 0), .03)
        // sphere4_2.translate(new Vector3(1, 0, 0), .03)

        //sphere1_1.rotateAround(new Vector3(0,0,0), new Vector3(0,1,0), .05)

        // const moveX = Input.GetAxis("Mouse X")

        sphere1_1.rotate(new Vector3(0,1,0), -.0125)
        sphere1_2.rotate(new Vector3(0,1,0), .0125)
        sphere1_3.rotate(new Vector3(0,1,0), .0125)
        sphere1_4.rotate(new Vector3(0,1,0), -.0125)
        sphere1_5.rotate(new Vector3(0,1,0), -.0125)
        sphere1_6.rotate(new Vector3(0,1,0), .0125)

        sphere2_1.rotate(new Vector3(1,0,0), .03)
        sphere2_2.rotate(new Vector3(1,0,0), .03)
        sphere2_3.rotate(new Vector3(1,0,0), .03)
        sphere2_4.rotate(new Vector3(1,0,0), .03)
        sphere2_5.rotate(new Vector3(1,0,0), .03)
        sphere2_6.rotate(new Vector3(1,0,0), .03)

        sphere3_1.rotate(new Vector3(1,1,0), -.05)
        sphere3_2.rotate(new Vector3(1,1,0), -.05)
        sphere3_3.rotate(new Vector3(1,1,0), -.05)
        sphere3_4.rotate(new Vector3(1,1,0), -.05)
        sphere3_5.rotate(new Vector3(1,1,0), -.05)
        sphere3_6.rotate(new Vector3(1,1,0), -.05)

        sphere4_1.rotate(new Vector3(1,0,1), -.025)
        sphere4_2.rotate(new Vector3(1,0,1), -.025)
        sphere4_3.rotate(new Vector3(1,0,1), -.025)
        sphere4_4.rotate(new Vector3(1,0,1), -.025)
        sphere4_5.rotate(new Vector3(1,0,1), -.025)
        sphere4_6.rotate(new Vector3(1,0,1), -.025)

        sphere5_1.rotate(new Vector3(0,1,1), .07)
        sphere5_2.rotate(new Vector3(0,1,1), .07)
        sphere5_3.rotate(new Vector3(0,1,1), .07)
        sphere5_4.rotate(new Vector3(0,1,1), .07)
        sphere5_5.rotate(new Vector3(0,1,1), .07)
        sphere5_6.rotate(new Vector3(0,1,1), .07)

        if (camera.position.z < (sphere1_1.position.z - 9)) {
          camera.position.z += .1
        }

        if (camera.position.z > (sphere1_1.position.z - 9)) {
          camera.position.z -= .1
        }
    })

    // scene.onPointerMove = (e, p) => {
    //   e.movementY
    //   p.hit
    // }

    scene.onPointerPick = (e, p) => {
      if (p.hit) {
        if (p.pickedMesh == this.box) {
          this.setNextMaterial()
        }
      }
    }

    // addEventListener('mousemove', (e) => {

    //     sphere1_1.lookAt(new Vector3(e.clientX, e.clientY, 0)) // , .0125)
    //     sphere1_2.lookAt(new Vector3(e.clientX, e.clientY, 0)) // , .0125)
    //     sphere1_3.lookAt(new Vector3(e.clientX, e.clientY, 0)) // , .0125)
    //     sphere1_4.lookAt(new Vector3(e.clientX, e.clientY, 0)) // , .0125)
    //     sphere1_5.lookAt(new Vector3(e.clientX, e.clientY, 0)) // , .0125)
    //     sphere1_6.lookAt(new Vector3(e.clientX, e.clientY, 0)) // , .0125)

    //     // sphere1_1.rotate(new Vector3(e.clientX, e.clientY, 0), .0125)
    //     // sphere1_2.rotate(new Vector3(e.clientX, e.clientY, 0), .0125)
    //     // sphere1_3.rotate(new Vector3(e.clientX, e.clientY, 0), .0125)
    //     // sphere1_4.rotate(new Vector3(e.clientX, e.clientY, 0), .0125)
    //     // sphere1_5.rotate(new Vector3(e.clientX, e.clientY, 0), .0125)
    //     // sphere1_6.rotate(new Vector3(e.clientX, e.clientY, 0), .0125)

    //     sphere2_1.lookAt(new Vector3(e.clientX, e.clientY, 0), .0125)
    //     sphere2_2.lookAt(new Vector3(e.clientX, e.clientY, 0), .0125)
    //     sphere2_3.lookAt(new Vector3(e.clientX, e.clientY, 0), .0125)
    //     sphere2_4.lookAt(new Vector3(e.clientX, e.clientY, 0), .0125)
    //     sphere2_5.lookAt(new Vector3(e.clientX, e.clientY, 0), .0125)
    //     sphere2_6.lookAt(new Vector3(e.clientX, e.clientY, 0), .0125)

    //     sphere3_1.lookAt(new Vector3(e.clientX, e.clientY, 0), .0125)
    //     sphere3_2.lookAt(new Vector3(e.clientX, e.clientY, 0), .0125)
    //     sphere3_3.lookAt(new Vector3(e.clientX, e.clientY, 0), .0125)
    //     sphere3_4.lookAt(new Vector3(e.clientX, e.clientY, 0), .0125)
    //     sphere3_5.lookAt(new Vector3(e.clientX, e.clientY, 0), .0125)
    //     sphere3_6.lookAt(new Vector3(e.clientX, e.clientY, 0), .0125)

    //     sphere4_1.lookAt(new Vector3(e.clientX, e.clientY, 0), .0125)
    //     sphere4_2.lookAt(new Vector3(e.clientX, e.clientY, 0), .0125)
    //     sphere4_3.lookAt(new Vector3(e.clientX, e.clientY, 0), .0125)
    //     sphere4_4.lookAt(new Vector3(e.clientX, e.clientY, 0), .0125)
    //     sphere4_5.lookAt(new Vector3(e.clientX, e.clientY, 0), .0125)
    //     sphere4_6.lookAt(new Vector3(e.clientX, e.clientY, 0), .0125)

    //     sphere5_1.lookAt(new Vector3(e.clientX, e.clientY, 0), .0125)
    //     sphere5_2.lookAt(new Vector3(e.clientX, e.clientY, 0), .0125)
    //     sphere5_3.lookAt(new Vector3(e.clientX, e.clientY, 0), .0125)
    //     sphere5_4.lookAt(new Vector3(e.clientX, e.clientY, 0), .0125)
    //     sphere5_5.lookAt(new Vector3(e.clientX, e.clientY, 0), .0125)
    //     sphere5_6.lookAt(new Vector3(e.clientX, e.clientY, 0), .0125)
    // })
  }

    private setNextMaterial() {
      this.materialIndex++
      if (this.materialIndex >= this.materials.length) {
        this.materialIndex = 0
      }
      this.box.material = this.materials[this.materialIndex]

      if (this.materialIndex == 3) { // explosion
        this.cardanoMaterial.diffuseColor = new Color3(0, 0, 1)
      } else {
        this.cardanoMaterial.diffuseColor = new Color3(1, 1, 1)
      }
    }
}

