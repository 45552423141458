






































































import { Component, Mixins } from 'vue-property-decorator'
import UtilsMixin from '@/mixins/UtilsMixin'
// import pixiShader from '@/components/PixiShader.vue'
// import blueSmoke from '@/components/BabylonSmoke.vue'

import cardanoLogo from '@/components/CardanoLogo.vue'

// import blueSmoke from '@/components/BabylonJSSmoke.vue'
// import blueSmoke from '@/components/BlueSmoke.vue'
// import blueSmoke from '@/components/ThreeSmoke.vue'
// import PixiRenderer from './PIXIRenderer.vue'
// import Sprite from './PIXISprite.vue'
// import Container from './PIXIContainer.vue'

@Component({
  name: 'home',
  components: {
     cardanoLogo
    // PixiRenderer,
    // Sprite,
    // Container
  }
})
export default class HomeComponent extends Mixins<UtilsMixin>(UtilsMixin) {

    // private scaleObject(container) {
    //   container.scale.x *= 1.25;
    //   container.scale.y *= 1.25;
    // }

    // private tickInfo(container, delta) {
    //   console.log(`Tick delta: ${delta}`)
    // }

}
