import { Scene } from '@babylonjs/core/scene'
import { ShaderMaterial } from '@babylonjs/core/Materials/shaderMaterial'
import { Effect } from '@babylonjs/core/Materials/effect'
import { Vector2 } from '@babylonjs/core/Maths/math.vector'

import * as defaultVertexShader from '@/materials/shaders/default.vertex.glsl'
import * as blueSmokeFragmentShader from '@/materials/shaders/BlueSmoke/blueSmoke.fragment.glsl'

Effect.ShadersStore['blueSmokeVertexShader'] = defaultVertexShader
Effect.ShadersStore['blueSmokeFragmentShader'] = blueSmokeFragmentShader

export class BlueSmokeMaterial extends ShaderMaterial {

    constructor(name: string, scene: Scene) {
        super(name, scene, { vertex: 'blueSmoke', fragment: 'blueSmoke' }, {
            uniforms: [
                'worldViewProjection',
                'time',
                'resolution',
                'alpha',
                'speed',
                'shift'
            ],
            attributes: [
                'position',
                'normal',
                'uv'
            ],
        })

        this.resolution = new Vector2(1920, 1080)

        this.alpha = 1.0
        this.shift = 1.6
        this.speed = new Vector2(0.7, 0.4)

        const startTime = Date.now()

        scene.registerBeforeRender(() => {
            const currentTime = Date.now()
            const time = currentTime - startTime

            this.time = time / 3000
        })
    }

    set time(value: number) {
        this.setFloat('time', value)
    }

    set alpha(value: number) {
      this.setFloat('alpha', value)
    }

    set shift(value: number) {
      this.setFloat('shift', value)
    }

    set resolution(value: Vector2) {
      this.setVector2('resolution', value)
    }

    set speed(value: Vector2) {
      this.setVector2('speed', value)
    }
}
