import { Scene } from '@babylonjs/core/scene'
import { ShaderMaterial } from '@babylonjs/core/Materials/shaderMaterial'
import { Effect } from '@babylonjs/core/Materials/effect'
import { Texture } from '@babylonjs/core/Materials/Textures'

import * as defaultVertexShader from '@/materials/shaders/default.vertex.glsl'
import * as poolFragmentShader from '@/materials/shaders/Pool/pool.fragment.glsl'

Effect.ShadersStore['poolVertexShader'] = defaultVertexShader
Effect.ShadersStore['poolFragmentShader'] = poolFragmentShader

export class PoolMaterial extends ShaderMaterial {

    constructor(name: string, scene: Scene) {
        super(name, scene, { vertex: 'pool', fragment: 'pool' }, {
            uniforms: [
                'worldViewProjection',
                'time',
                'uSampler'
            ],
            attributes: [
                'position',
                'normal',
                'uv'
            ],
        })

        const startTime = Date.now()

        scene.registerBeforeRender(() => {
            const currentTime = Date.now()
            const time = currentTime - startTime

            this.time = time / 1000

            this.uSampler = new Texture('./logo.png', null)
        })
    }

    set time(value: number) {
        this.setFloat('time', value)
    }

    set uSampler(value: Texture) {
      this.setTexture('uSampler', value)
  }
}
