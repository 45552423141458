




























import Vue from 'vue';

const coveFooter = () => import ('@/components/Footer.vue')

export default Vue.extend({
  name: 'App',

  components: {
    coveFooter
  },

  data: () => ({
    //
  }),
});
