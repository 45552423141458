import { Scene } from '@babylonjs/core/scene'
import { ShaderMaterial } from '@babylonjs/core/Materials/shaderMaterial'
import { Effect } from '@babylonjs/core/Materials/effect'

import * as defaultVertexShader from '@/materials/shaders/default.vertex.glsl'
import * as explosionFragmentShader from '@/materials/shaders/Explosion/explosion.fragment.glsl'
import { Vector4 } from '@babylonjs/core/Maths/math.vector'

Effect.ShadersStore['explosionVertexShader'] = defaultVertexShader
Effect.ShadersStore['explosionFragmentShader'] = explosionFragmentShader

export class ExplosionMaterial extends ShaderMaterial {

    constructor(name: string, scene: Scene) {
        super(name, scene, { vertex: 'explosion', fragment: 'explosion' }, {
            uniforms: [
                'worldViewProjection',
                'time',
                'vPosition'
            ],
            attributes: [
                'position',
                'normal',
                'uv'
            ],
        })

        const startTime = Date.now()

        scene.registerBeforeRender(() => {
            const currentTime = Date.now()
            const time = currentTime - startTime

            this.time = time / 1000
        })
    }

    set time(value: number) {
        this.setFloat('time', value)
    }

    set vPosition(value: Vector4) {
      this.setVector4('vPosition', value)
    }
}
