import * as configData from '@/configData.json'

export default {

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  configData: (configData as any).default,

  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  deepCopy(object: any): any {
    // return Object.assign({}, object)
    return JSON.parse(JSON.stringify(object))
  },

  routes: {
    login: '/account/login',
  },

  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  async get(url: string): Promise<any> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const headers: any = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': localStorage.csrf
    }

    if (localStorage.access_token) {
      headers.Authorization = 'Bearer ' + localStorage.access_token
    }

    let response: Response | undefined
    try {
      response = await fetch(url, {
        headers,
        credentials: 'same-origin', // send auth cookie for csrf
        method: 'GET'
        })

      if (!response.ok) {
        if (response.status === 401 || response.status === 403) {
          window.location.href=`${this.routes.login}?redirect=${window.location.pathname}${window.location.search}`
          return
        }

        throw new Error(`${response.status}: ${response.statusText}`)
      } else {
        const r = await response.json()
        // console.log('utils get: r', r)

        return r
      }
    } catch (exception) {
      return { json: undefined, exception: exception.message, response }
    }
  },

  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  async post(url: string, data: any): Promise<any> {
    return await this.set(url, 'POST', data)
  },

  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  async put(url: string, data: any): Promise<any> {
    return await this.set(url, 'PUT', data)
  },

  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  async set(url: string, action: string, data: any): Promise<any> {

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const headers: any = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': localStorage.csrf
    }

    if (localStorage.access_token) {
      headers.Authorization = 'Bearer ' + localStorage.access_token
    }

    let response: Response | undefined
    try {
      response = await fetch(url, {
        headers,
        credentials: 'same-origin', // send auth cookie for csrf
        method: action,
        body: JSON.stringify(data)
        })

      if (!response.ok) {
        if (response.status === 401 || response.status === 403) {
          window.location.href=`${this.routes.login}?redirect=${window.location.pathname}${window.location.search}`
          return
        }

        throw new Error(`${response.status}: ${response.statusText}`)
      } else {
        const r = await response.json()
        // console.log('utils set: r', action, r)

        return r
      }
    } catch (exception) {
      // eslint-disable-next-line no-console
      console.error('utils set: exception', exception)
      // eslint-disable-next-line no-console
      console.warn('data', data)
      // eslint-disable-next-line no-console
      console.warn('JSON.stringify(data)', JSON.stringify(data))
      return { json: undefined, exception: exception.message, response }
    }
  },

  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  async delete(url: string): Promise<any> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const headers: any = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': localStorage.csrf
    }

    if (localStorage.access_token) {
      headers.Authorization = 'Bearer ' + localStorage.access_token
    }

    let response: Response | undefined
    try {
      response = await fetch(url, {
        headers,
        credentials: 'same-origin', // send auth cookie for csrf
        method: 'DELETE'
      })

      if (!response.ok) {
        if (response.status === 401 || response.status === 403) {
          window.location.href=`${this.routes.login}?redirect=${window.location.pathname}${window.location.search}`
          return
        }

        throw new Error(`${response.status}: ${response.statusText}`)
      } else {
        const r = await response.json()
        // console.log('utils delete: r', r)

        return r
      }
    } catch (exception) {
      return { json: undefined, exception: exception.message, response }
    }
  }
}
